/* ########################   GLOBAL CLASSES TO USE ######################### */
* {
	margin: 0;
	font-family: itc-avant-garde-gothic-pro;
}

.MuiCircularProgress-determinate,
.MuiCircularProgress-circleDeterminate {
	transition: transform 2ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
}

.emed-header {
	height: 50px;
	background-color: #000;
	display: grid;
	place-items: center;
}

.bg-grey {
	background-color: #e0e0e0;
}

.pointer {
	cursor: pointer;
}

.progress-pie-container {
	position: absolute;
	width: 50px;
	z-index: 400;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.btn-navigation {
	padding: 0.9em 0 1.2em 0;
	width: 100%;
	display: block;
	cursor: pointer;
	background-color: transparent;
	border-radius: 2em;
	border: none;
}

/* ############################ SPINNER ######################### */

.spinner-backdrop {
	position: absolute;
	bottom: 0;
	top: 0;
	background-color: rgba(65, 65, 65, 0.212);
	z-index: 999;
	width: 100%;
	height: 100%;
	display: grid;
	place-items: center;
}

.spinner {
	box-sizing: border-box;
	animation: 1s linear infinite spin;
	border: solid 5px #000000;
	border-bottom-color: #f0f0f0;
	border-radius: 50%;
	content: "";
	height: 40px;
	width: 40px;
}

.loading-text {
	position: absolute;
	top: 40%;
	left: 50%;
	transform: translateX(-50%);
}

.landing-page {
	width: 100vw;
	height: 100vh;
	background-color: rgba(35, 35, 35, 0.784);
}

/* ############################ MODAL ######################### */
.dialog .MuiDialog-paper {
	border-radius: 12px !important;
}

.landing-page .MuiDialog-container {
	background-color: rgba(35, 35, 35, 0.784) !important;
}

.camera-permission {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem;
	text-align: center;
}

.camera-permission h3 {
	margin: 1rem 0;
	font-size: 20px;
}

.dialog-legend-mobile {
	text-align: center;
	position: absolute;
	top: 80%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 5555;
	color: #fff;
	width: 100%;
}

.dialog-legend-mobile a {
	display: inline-block;
	color: #fff;
}

.modal {
	display: block;
	position: fixed;
	z-index: 1555;
	left: 0;
	top: 0;
	right: 0;
	height: 100%;
	overflow: auto;
	background-color: rgb(0, 0, 0);
	background-color: rgba(0, 0, 0, 0.4);
	margin: auto;
}

.modal-content {
	background-color: #fefefe;
	width: 80%;
	max-width: 350px;
	margin: 25% auto;
	padding: 20px;
	border: 1px solid #888;
	border-radius: 18px;
	display: flex;
	flex-direction: column;
}

.modal-content h2 {
	font-weight: 800;
	font-size: 32px;
	line-height: 40px;
	text-align: center;
}

.modal-content p {
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
	text-align: center;
	text-transform: capitalize;
}

.modal-content img {
	width: 100%;
	margin-bottom: 2em;
}

.close {
	color: #aaa;
	align-self: flex-end;
	font-size: 28px;
	font-weight: bold;
}

/* ############################ BOX VALIDATOR ######################### */

.validator-app-container {
	position: relative;
	background-color: rgba(0, 0, 0, 0.719);
}

.close-btn {
	position: absolute;
	right: 20px;
	top: 20px;
	z-index: 555;
	cursor: pointer;
}

.app-container {
	min-height: 640px;
}

.border-radius {
	border-radius: 4px;
}

.validator-app {
	height: 100vh;
	width: 100%;
	max-width: 32rem;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
}

.text-legend {
	position: absolute;
	top: 15%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 90%;
	z-index: 450;
}

.validator-app h3,
.validator-app p {
	color: #fff;
	position: relative;
	z-index: 444;
	text-align: center;
	margin: 20px 12px;
}

.validator-app h3 {
	font-size: 28px;
}

.validator-app p {
	font-size: 16px;
	line-height: 20px;
}

#video {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	height: 100vh;
	object-fit: cover;
}

.video-overlay {
	position: absolute;
	top: 50%;
	left: 50%;
	max-width: 432px;
	width: 80vw;
	height: 80vw;
	max-height: 432px;
	box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.656);
	z-index: 400;
	transform: translate(-50%, -50%);
}

.corners {
	position: relative;
	height: 100%;
	width: 100%;
	z-index: 700;
}

.corners:before,
.corners:after,
.corners > :first-child:before,
.corners > :first-child:after {
	position: absolute;
	content: "";
	width: 47px;
	height: 47px;
	border: solid 8px #fff;
	z-index: 700;
}

.corners:before {
	top: 0;
	left: 0;
	border-width: 8px 0 0 8px;
}

.corners:after {
	top: 0;
	right: 0;
	border-width: 8px 8px 0 0;
}

.corners > :first-child:before {
	bottom: 0;
	right: 0;
	border-width: 0 8px 8px 0;
}

.corners > :first-child:after {
	bottom: 0;
	left: 0;
	border-width: 0 0 8px 8px;
}

.scan-bar::before {
	content: "";
	position: absolute;
	top: 0;
	width: 100%;
	height: 10px;
	background: linear-gradient(#18c89b, #8fffe3c4);
	animation: scan 2s ease-in-out infinite alternate, trace 2s ease-in-out infinite alternate;
	z-index: 666;
}

.bottom-texts {
	margin: 1em;
	width: 95vw;
	max-width: 600px;
	max-height: 600px;
	margin: 1em auto;
}

.variable-text {
	top: 50%;
	transform: translateY(-50%);
	margin-top: 1em;
	text-align: center;
	width: 100%;
	padding: 10px;
	background-color: #d8d8d8;
	border-radius: 20px;
}

.choose {
	padding-top: 6px;
	text-align: center;
	font-weight: bold;
	text-decoration: underline;
	color: #000;
}

/* ############################ COVID TEST PAGE ######################### */

.tests-list {
	box-shadow: inset -6px 0px 12px rgba(128, 128, 128, 0.082);
	margin-bottom: 1em;
}

.tests-list::-webkit-scrollbar {
	width: 8px;
}

.tests-list::-webkit-scrollbar-track {
	border-radius: 10px;
	background-color: rgb(255, 255, 255);
}

.tests-list::-webkit-scrollbar-thumb {
	background: #ced4da;
	border-radius: 10px;
}

.test-list-page h1 {
	font-size: 28px;
	font-weight: 700;
	text-align: left;
	margin-bottom: 1.2em;
}

.tests-container {
	padding: 2rem 0rem;
	height: 95vw;
	width: 95vw;
	max-width: 600px;
	max-height: 600px;
	margin: auto;
}

.test-item {
	display: flex;
	border: 1px solid #cccccc;
	align-items: center;
	padding: 8px;
	border-radius: 4px;
	margin-bottom: 12px;
	cursor: pointer;
}

.test-item img {
	margin-right: 16px;
	width: 15%;
}

.test-item h3 {
	font-size: 14px;
	font-weight: 700;
}

.test-item p {
	font-size: 14px;
}

.test-item .check-icon {
	width: 14px;
	height: 14px;
	background-image: url("data:image/svg+xml,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.3333 2L5 9.33333L1.66667 6' stroke='%2349EFB3' stroke-width='2.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
	background-repeat: no-repeat;
	background-position: center;
	margin: auto 12px auto auto;
}

.tests-container button {
	color: #fff;
	background-color: #000000;
	width: 100%;
	padding: 0.8em;
	margin-top: 1.5em;
	cursor: pointer;
}

.tests-container button.disabled {
	opacity: 0.4;
	pointer-events: none;
	cursor: not-allowed;
}

.item-active {
	border: 1px solid #000;
}

.test-item .warning-text {
	color: #be0e0e;
	font-size: 14px;
}

.test-item .warning-container {
	display: flex;
	margin-top: 4px;
}

.test-item .warning-icon {
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23be0e0e' d='M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z'/%3E%3C/svg%3E");
	width: 14px;
	height: 14px;
	display: inline-block;
	background-position: center;
	margin-right: 6px;
}

.check-mark-image {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 4433;
}

.successful-scanning {
	border: solid 8px #49efb3;
	height: 100%;
}
/* ############################ LANDING PAGE ######################### */

.homepage {
	width: calc(100% - 20vw);
	margin: auto;
	padding-top: 5em;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.homepage h1 {
	font-style: normal;
	font-weight: 700;
	font-size: 40px;
	line-height: 40px;
	text-align: center;
}

.camera-icon {
	width: 60%;
	height: 10em;
	margin: 4em 0;
	background-image: url("data:image/svg+xml,%3Csvg width='179' height='148' viewBox='0 0 179 148' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M162.399 24.0218H130.569L118.889 0.924133H60.1475L48.4301 24.0218H47.4532V18.8387C47.4532 14.5752 43.9974 11.1206 39.7352 11.1206H25.5189C21.2756 11.1206 17.8009 14.5765 17.8009 18.8387V24.0218H16.5988C7.60427 24.0218 0.298157 31.3452 0.298157 40.3225V131.176C0.298157 140.17 7.62148 147.477 16.5988 147.477H162.38C171.375 147.477 178.681 140.153 178.681 131.176L178.68 40.3225C178.698 31.3267 171.394 24.0218 162.398 24.0218H162.399ZM26.1967 19.4771H39.1163V24.0218H26.1967V19.4771ZM170.344 131.175C170.344 135.55 166.776 139.118 162.401 139.118H16.6C12.2248 139.118 8.65677 135.55 8.65677 131.175L8.65553 40.3212C8.65553 35.946 12.2232 32.378 16.5988 32.378H53.5754L65.2927 9.28026H113.744L125.443 32.378H162.42C166.795 32.378 170.363 35.9456 170.363 40.3212L170.365 131.175H170.344ZM89.5191 44.3225C66.6654 44.3225 48.0921 62.914 48.0921 85.7495C48.0921 108.585 66.6836 127.177 89.5191 127.177C112.354 127.177 130.946 108.585 130.946 85.7495C130.946 62.9143 112.355 44.3225 89.5191 44.3225ZM89.5191 119.853C70.7209 119.853 55.416 104.547 55.416 85.7495C55.416 66.9512 70.7212 51.6464 89.5191 51.6464C108.317 51.6464 123.622 66.9516 123.622 85.7495C123.622 104.547 108.318 119.853 89.5191 119.853ZM158.025 46.2403C158.025 48.5499 156.147 50.4279 153.837 50.4279H144.035C141.725 50.4279 139.847 48.5501 139.847 46.2403C139.847 43.9305 141.725 42.0527 144.035 42.0527H153.837C156.165 42.0527 158.025 43.9305 158.025 46.2403Z' fill='black'/%3E%3C/svg%3E%0A");
	background-position: center;
	background-repeat: no-repeat;
}

.homepage p {
	font-size: 24px;
	line-height: 30px;
	text-align: center;
	margin: 1em 0 3em 0;
}

.homepage button,
.modal-content .button-link {
	color: white;
	background-color: #000000;
	display: block;
	text-align: center;
	font-weight: 700;
	font-size: 20px;
	line-height: 24px;
	padding: 0.8em;
	text-decoration: none;
}

#canvas {
	display: none;
}

.timer {
	max-width: 600px;
	margin: auto;
}

.timeout-screen {
	padding: 2rem;
	text-align: center;
}

.timeout-screen .circle {
	border-radius: 50%;
	padding: 3rem;
	font-size: 32px;
	border: solid 12px black;
	aspect-ratio: 1/1;
	color: black;
	display: grid;
	place-items: center;
	margin: 2rem 0;
}

.hide-timer {
	display: none;
}

.video-countdown {
	position: absolute;
	left: 50%;
	top: 25%;
	transform: translate(-50%, -50%);
	z-index: 333;
	color: #fff;
	font-size: 2em;
	border-radius: 50%;
	border: solid 2px #fff;
	width: 55px;
	height: 55px;
	display: grid;
	box-sizing: border-box;
	place-items: center;
}

.video-countdown span {
	display: block;
	margin-bottom: -7px;
}

.dialog-content {
	line-height: 1.4em;
}

.alert-camera {
	display: inline-block;
	margin: 0 10px;
}

.prompt-desktop-overlay {
	display: none;
}

.dialog-legend-desktop a {
	text-decoration: none;
	color: #1860f0;
	display: inline-block;
	margin-top: 12px;
}

.MuiBackdrop-root {
	background-color: #fff !important;
	opacity: 0 !important;
}
/* ##################### ANIMATIONS ##################### */

@keyframes scan {
	to {
		transform: translateY(-100%);
		top: 100%;
	}
}

@keyframes trace {
	0% {
		box-shadow: 0px 14px 26px #18c89b;
	}

	100% {
		box-shadow: 0px -14px 26px #18c89b;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* ################# MEDIA QUERIES ##################### */

@media only screen and (min-width: 800px) {
	.landing-page {
		background-color: #fff;
	}

	.validator-app {
		max-width: 36rem;
	}

	.validator-app-container {
		background-color: transparent;
	}

	.validator-app-container p,
	.validator-app-container h3 {
		color: #000 !important;
	}

	.camera-permission p {
		width: 70%;
		line-height: 152%;
	}

	.camera-permission h3 {
		font-size: 36px;
	}

	.dialog .MuiDialog-paper {
		border-radius: 12px !important;
		padding: 2rem;
		box-shadow: 2px 6px 12px rgba(139, 139, 139, 0.05), 2px 4px 6px rgba(139, 139, 139, 0.1),
			0px 1px 2px rgba(25, 25, 25, 0.1);
		max-width: 586px;
	}

	.dialog-legend {
		color: #000;
	}

	.dialog-legend-mobile {
		display: none;
	}

	.dialog-legend-desktop {
		display: block;
	}

	.dialog-break-desktop {
		display: none;
	}

	.MuiBackdrop-root {
		background-color: #fff !important;
		opacity: 0 !important;
	}

	.video-overlay {
		box-shadow: 0 0 0 0;
		width: 75%;
		top: 65%;
	}

	.corners:before,
	.corners:after,
	.corners > :first-child:before,
	.corners > :first-child:after {
		border: solid 8px #49efb3;
	}

	.corners:before {
		top: 0;
		left: 0;
		border-width: 8px 0 0 8px;
	}

	.corners:after {
		top: 0;
		right: 0;
		border-width: 8px 8px 0 0;
	}

	.corners > :first-child:before {
		border-width: 0 8px 8px 0;
	}

	.corners > :first-child:after {
		border-width: 0 0 8px 8px;
	}

	#video {
		bottom: 50%;
		top: unset;
		transform: translate(-50%, 60%);
		max-width: 432px;
		max-height: 432px;
	}

	.video-overlay {
		box-sizing: border-box;
		bottom: 50%;
		top: unset;
		left: 50%;
		transform: translate(-50%, 60%);
		max-width: unset;
		max-height: unset;
		width: 432px;
		height: 432px;
	}

	.successful-scanning {
		height: 98%;
	}

	.prompt-desktop-overlay {
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.815);
		position: absolute;
		z-index: 555;
	}
}
